import request from "@/utils/request";

/**
 * 创建新闻【ok】
 * @param params
 * @returns {*}
 */
export function createNews(params) {
  return request({
    url: "/api/backend/news/createNews",
    method: "post",
    data: params
  });
}

/**
 * 查询所有的新闻列表【ok】
 * @param params
 * @returns {*}
 */
export function getNewsList(params) {
  return request({
    url: "/api/backend/news/getNewsList",
    method: "get",
    params
  });
}

/**
 * 切换状态
 * @param params
 * @returns {*}
 */
export function switchStatus(params) {
  return request({
    url: "/api/backend/news/switchStatus",
    method: "post",
    data: params
  });
}
